import React, { Fragment } from 'react'

import Navigation from '~/components/Navigation'
import SEO from '~/components/seo'
import ProductGrid from '~/components/ProductGrid'

const IndexPage = () => (
  <Fragment>
    <SEO
      title="Home"
      keywords={[
        `craft beer`,
        `greyhound`,
        `ipa`,
        'doble ipa',
        'greyhound',
        'llanura',
        'neipa',
        'cerveza',
        'manchega',
      ]}
    />
    <Navigation />
    <ProductGrid />
  </Fragment>
)

export default IndexPage
