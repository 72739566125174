import React, { useContext, useEffect, useState, useCallback } from 'react'
import { useMetadata } from '~/utils/metadata'
import { MdAddShoppingCart } from 'react-icons/md'

import StoreContext from '~/context/StoreContext'

const latasInSku = process.env.GATSBY_SHOP_UNITS_IN_SKY || 2

const fields = [
  { id: 'estilo', label: 'Estilo' },
  { id: 'datos', label: 'Vol% / IBU / EBC' },
  { id: 'apariencia', label: 'Apariencia' },
  { id: 'caracteristicas', label: 'Caracteristicas' },
  { id: 'lupulos', label: 'Lupulos' },
  { id: 'cantidad', label: 'Cantidad' },
]

const ProductThumbnail = ({ product }) => {
  const {
    addVariantToCart,
    store: { client, adding },
  } = useContext(StoreContext)

  const {
    handle,
    variants: [productVariant],
  } = product

  const metadata = useMetadata(handle)
  const [available, setAvailable] = useState(productVariant.availableForSale)

  const checkAvailability = useCallback(
    (productId) => {
      client.product.fetch(productId).then((fetchedProduct) => {
        // this checks the currently selected variant for availability
        const result = fetchedProduct.variants.filter(
          (variant) => variant.id === productVariant.shopifyId
        )
        if (result.length > 0) {
          setAvailable(result[0].available)
        }
      })
    },
    [client.product, productVariant.shopifyId]
  )

  useEffect(() => {
    checkAvailability(product.shopifyId)
  }, [productVariant, checkAvailability, product.shopifyId])

  const handleAddToCart = () => {
    if (window.fbq) {
      window.fbq('track', 'AddToCart')
    }
    addVariantToCart(productVariant.shopifyId, 1)
  }

  const color = metadata ? metadata.color : ''
  return (
    <section id={handle} style={{ color }} itemScope itemType="http://schema.org/Product">
      <style
        dangerouslySetInnerHTML={{
          __html: `
          section#${handle}::before {
            background-color: ${color};
            background-image: url(/images/poster/${handle}.jpg);
          }
          section#${handle} .btn:hover {
            background-color: ${color}
          }`,
        }}
      ></style>
      <style
        dangerouslySetInnerHTML={{
          __html: `section#${handle}::after { background-image: url(/images/latas/${handle}.png)`,
        }}
      ></style>

      {/* {firstImage && firstImage.localFile && (
        <div className="bg-image">
          <Img fluid={firstImage.localFile.childImageSharp.fluid} alt={handle} />
        </div>
      )} */}
      <div className="info">
        <h2 itemProp="name">{product.title}</h2>
        <img
          itemProp="image"
          style={{ opacity: 0, with: 0, height: 0 }}
          src={`url(/images/latas/${handle}.png)`}
          alt={`Lata ${product.title}`}
        />
        <div className="metadata">
          {metadata &&
            fields.map(({ id, label }) => {
              if (!metadata[id]) return null
              const formattedLabel =
                id === 'datos' && metadata[id].includes('/')
                  ? label
                  : label.replace(' / IBU / EBC', '')
              return (
                <div key={id} className="category">
                  {formattedLabel}
                  <span className="property">{metadata[id]}</span>
                </div>
              )
            })}
        </div>
        <button className="btn btn_wide" disabled={!available | adding} onClick={handleAddToCart}>
          {available
            ? `Añadir pack de ${latasInSku} latas al carrito (${productVariant.price}€)`
            : 'Agotadas temporalmente'}
          {available && <MdAddShoppingCart />}
        </button>
      </div>
    </section>
  )
}

export default ProductThumbnail
