import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { meta } from '~/utils/metadata'
import './products.styles.css'

import ProductThumbnail from '~/components/ProductThumbnail'

const ProductGrid = () => {
  const { allShopifyProduct } = useStaticQuery(
    graphql`
      query {
        allShopifyProduct(
          filter: { availableForSale: { in: true } }
          sort: { fields: [updatedAt], order: DESC }
        ) {
          edges {
            node {
              id
              title
              handle
              updatedAt
              productType
              shopifyId
              images {
                id
                originalSrc
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 910) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                  }
                }
              }
              variants {
                id
                title
                price
                availableForSale
                shopifyId
              }
            }
          }
        }
      }
    `
  )

  const products = allShopifyProduct.edges
    ? allShopifyProduct.edges.map(({ node }) => {
        const staticMetadata = meta[node.handle] || {}
        // const metadata = node.metafields.reduce(
        //   (acc, meta) => ({ ...acc, [meta.key]: meta.value }),
        //   {}
        // )
        return {
          ...node,
          metadata: { ...staticMetadata },
        }
      })
    : []

  const [suggestScrollHidden, setSuggestScrollHidden] = useState(false)
  const isMobile = typeof window !== 'undefined' ? window.innerWidth < 860 : false
  const initialColor =
    products.length && products[0].metadata ? products[0].metadata.color : 'transparent'
  const [currentColor, setCurrentColor] = useState(initialColor)

  useEffect(() => {
    const onScroll = () => {
      const currentScroll = document.body.scrollTop || document.documentElement.scrollTop
      const height = isMobile ? 660 : window.innerHeight
      const heightRatio = isMobile ? 0.25 : 0.5
      const currentSectionIndex = Math.floor((currentScroll + height * heightRatio) / height)
      const currentSection = products[currentSectionIndex]
      if (!suggestScrollHidden && currentScroll > 40) {
        setSuggestScrollHidden(true)
      }
      if (currentSection && currentSection.metadata && currentSection.metadata.color) {
        setCurrentColor(currentSection.metadata.color)
      }
    }

    var supportsPassive = false
    try {
      var opts = Object.defineProperty({}, 'passive', {
        get: () => {
          supportsPassive = true
        },
      })
      window.addEventListener('testPassive', null, opts)
      window.removeEventListener('testPassive', null, opts)
    } catch (e) {}

    window.addEventListener('scroll', onScroll, supportsPassive ? { passive: true } : false)

    return () => {
      if (onScroll) {
        window.removeEventListener('scroll', onScroll)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products])

  return (
    <main>
      <style
        dangerouslySetInnerHTML={{
          __html: `main::after {
          border-color: ${currentColor};
        })`,
        }}
      ></style>
      {products.map((product) => (
        <ProductThumbnail key={product.id} product={product} />
      ))}
      {!isMobile && (
        <div className="scroll_hint" style={{ opacity: suggestScrollHidden ? 0 : 1 }}>
          <div className="mousey">
            <div className="scroller"></div>
          </div>
        </div>
      )}
    </main>
  )
}

export default ProductGrid
