export const meta = {
  'golden-ale': {
    color: '#F5B833',
    estilo: 'Lager',
    datos: '5,5',
    cantidad: '33cl',
  },
  'red-ale': {
    color: '#87171F',
    estilo: 'Red Ale',
    datos: '6,0',
    cantidad: '33cl',
  },
  'a-new-hope': {
    color: '#e5c163',
    estilo: 'Neipa',
    datos: '6 / 25 / 7',
    lupulos: 'Amarillo Cryo, Citra Cryo, El Dorado',
    apariencia: 'Turbidez alta',
    caracteristicas: 'Frutas cítricas y tropicales',
    cantidad: '44cl',
  },
  'executioners-hops': {
    color: '#F07F02',
    estilo: 'Doble Neipa',
    datos: '8 / 30 / 8',
    lupulos: 'Citra Cryo y Idaho 7 cryo',
    apariencia: 'Turbidez alta',
    caracteristicas: 'Frutas tropicales y cítrica',
    cantidad: '44cl',
  },
  'hop-stalkers': {
    color: '#78C502',
    estilo: 'Neipa',
    datos: '6 / 30 / 5',
    lupulos: 'Citra, Azacca, Citra Cryo, Cashmere Cryo y Mosaic Cryo',
    apariencia: 'Turbidez alta',
    caracteristicas: 'Aroma a frutas tropicales y sabor a cítricos, fruta de hueso y final resinoso',
    cantidad: '44cl',
  },
  'hopem-all': {
    color: '#f38983',
    estilo: 'Neipa',
    datos: '7 / 25 / 7',
    lupulos: 'Cascade, Mandarina Bavaria, Cryo Citra y Cryo Amarillo',
    apariencia: 'Turbidez alta',
    caracteristicas: 'Frutal y cítrica',
    cantidad: '44cl',
  },
  'all-hops-no-brain': {
    color: '#79649c',
    estilo: 'Doble Ipa',
    datos: '8 / 30 / 8',
    lupulos: 'El dorado, Cryo Mosaic y Cryo Citra',
    apariencia: 'Turbidez alta',
    caracteristicas: 'Frutas tropicales',
    cantidad: '44cl',
  },
  'giveem-hops': {
    color: '#9E3D70',
    estilo: 'Doble Neipa',
    datos: '8 / 30 / 7',
    lupulos: 'Simcoe, Amarillo, Mosaic Cryo y Citra Cryo',
    apariencia: 'Turbidez media-alta',
    caracteristicas: 'Frutas tropicales',
    cantidad: '44cl',
  },
  'slave-of-new-hops': {
    color: '#57CED8',
    estilo: 'IPA',
    datos: '8,7 / 57 / 9',
    lupulos: 'Simco, Azzaca, Ekuanot y Simcoe Cryo',
    apariencia: 'Turbidez media-baja',
    caracteristicas: 'Afrutada, final seco',
    cantidad: '44cl',
  },
  'born-of-the-hops': {
    color: '#4B7040',
    estilo: 'West Coast IPA',
    datos: '7 / 52 / 18',
    lupulos: 'Simcoe, Cascade y Columbus',
    apariencia: 'Turbidez media-alta',
    caracteristicas: 'Herbal, resinosa, maltosa',
    cantidad: '44cl',
  },
  'fistful-of-hops': {
    color: '#D593BD',
    estilo: 'Hazy session IPA',
    datos: '5 / 23 / 6',
    lupulos: 'El dorado, Simcoe, Simcoe Cryo',
    apariencia: 'Turbidez alta',
    caracteristicas: 'Frutas tropicales',
    cantidad: '44cl',
  },
  'cryo-hops': {
    color: '#B36D54',
    estilo: 'Neipa',
    datos: '6,5 / 30 / 8',
    lupulos: 'Citra cryo y mosaic cryo',
    apariencia: 'Turbidez alta',
    caracteristicas: 'Frutas tropicales y cítrica',
    cantidad: '44cl',
  },
  nib: {
    color: '#5D506B',
    estilo: 'Hoppy saison',
    datos: '7 / 33 / 6',
    caracteristicas: 'Aromas de frutas y especias',
    cantidad: '44cl',
  },
  'hopping-hostile': {
    color: '#b32a22',
    estilo: 'Neipa',
    datos: '6,5 / 30 / 8',
    lupulos: 'sabro cryo y mosaic cryo',
    apariencia: 'Turbidez alta',
    caracteristicas: 'Frutas tropicales',
    cantidad: '44cl',
  },
  'hopping-is-my-business': {
    color: '#dbc94b',
    estilo: 'Doble IPA',
    datos: '9 / 55 / 10',
    lupulos: 'citra, mosaico, amarillo y Centenniall',
    apariencia: 'Transparencia media',
    caracteristicas: 'Frutas de hueso, resina',
    cantidad: '44cl',
  },
}

export const useMetadata = (handler) => {
  return meta[handler]
}
